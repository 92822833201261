import Loader from "@/client/components/Loader";
import useAuth from "@/client/auth/useAuth";

/*
situations:
1. the app is not installed => redirect to oauth
2. the app is installed, embedded=1 is not present => redirect to embedded app url
3. the app is installed, embedded=1 is present => redirect to dashboard
*/

const Index = () => {
  useAuth();
  return <Loader />;
};

export default Index;
