import { useEffect } from "react";
import { useRouter } from "next/router";
import logger from "@/server/logger";

/*
situations:
1. the app is not installed => redirect to oauth
2. the app is installed, embedded=1 is not present => redirect to embedded app url
3. the app is installed, embedded=1 is present => redirect to dashboard
*/

const useAuth = () => {
  const router = useRouter();
  useEffect(() => {
    const redirect = async ({ query }) => {
      try {
        const { host, embedded } = query;

        // situation 3: app is installed, and embedded=1 is present -> redirect to the dashboard
        if (host && embedded === "1") {
          router.push({
            pathname: process.env.NEXT_PUBLIC_DASHBOARD_PATH,
            query,
          });
          return;
        }

        // check if the app is installed
        const res = await fetch("/api/auth", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(query),
        });
        const data = await res.json();
        const { redirect, installed } = data;

        if (!installed && redirect) {
          // situation 1. the app is not embedded and is not installed -> we redirect to oauth process
          window.location.href = redirect;
        } else {
          if (installed && host) {
            // situation 2. the app is installed, "embedded=1" is NOT present -> redirect to embedded app url
            window.location.href = `https://${atob(host)}/apps/${
              process.env.NEXT_PUBLIC_SHOPIFY_API_KEY
            }`; // atob decodes Base64 encoded data
          } else {
            // something is missing
          }
        }
      } catch (error) {
        logger.error({
          msg: "error initiating auth",
          error,
        });
      }
    };

    // if we have query parameters, we execute the function above and pass along the query parameters
    // maybe we could rewrite everything inside useEffect() so it's more straightforward
    if (Object.keys(router?.query ?? {}).length) {
      redirect(router);
    }
  }, [router]);
};

export default useAuth;
